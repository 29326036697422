html {
  scroll-behavior: smooth;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

body {
  background-color: #fff !important;
  overflow-x: hidden;
}

#root {
  height: 100vh;
}

@media only screen and (min-width: 640px) {
  .content-container {
    box-shadow: none !important;
    border-radius: 0px !important;
    max-width: 1024px;
    margin: auto;
  }
}

/* override chonky search */
.chonky-searchFieldContainer {
  display: none !important;
}

/* override chonky childrenCount and shows without focus */
div[class^="fileIcon"], div[class*="fileIcon"] {
  opacity: 100 !important;
  font-weight: bolder;
}

/* override bootstrap accordion */
.accordion {
  --bs-accordion-active-bg: transparent !important;
  --bs-accordion-bg: transparent !important;;
}